@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.links-feature {
    padding: 4% 8%;
    background-color: #191919;
}

.feature-title {
    display: block;
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 108px;
    margin-top: 16px;

    color: #ffffff;
}

.feature-description {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    color: #b3b3b3;
}

.links {
    display: flex;
    gap: 24px;
}

.link {
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }
}

@include media-tablet {
    .links-feature {
        padding: 4% 16px;
    }

    .feature-title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 72px;

        max-width: 15ch;
    }

    .feature-description {
        font-size: 16px;
        line-height: 19px;
    }

    .link {
        font-size: 14px;
        line-height: 17px;
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
        margin-bottom: 24px;
    }

    .links {
        flex-direction: column;
        gap: 12px;
    }
}

@include media-mobile-up {
    .link {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.4)
    }
}